import styled from "styled-components";

export const Main = styled.main`
    flex: auto;
`

export const TemplateSt = styled.div`
    height: 100%;
`

export const MobileWhatsIcon = styled.a`
    img {
        position: fixed;
        right: 1.5%;
        bottom: 2%;
        z-index: 5000;
        width: 50px;
        height: 50px;
    }

    @media (min-width: 965px) { 
        display: none;
    }
`

