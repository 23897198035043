import Template from "../../shared/Template/Template";
import { Navigation, Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import general from '../../assets/banners/bannerGeneral.jpg'
import rzr from '../../assets/banners/bannerRZR.jpg'
import sport from '../../assets/banners/bannerSport.jpg'
import ranger from '../../assets/banners/bannerRanger.jpg'
import { Banner, BannerInfo, CarName, CarouselTitle, GroupItem, GroupsList, HomeContainer, Image, Price, VehiclesCarousel, Wrapper, BannerTitle, BannerText, BannerMobile, MobileImage } from "./styles";
import { useEffect, useState } from 'react'
import vehiclesList from "../../utils/vehiclesList";
import { currencyFormat, vehicleJsonToPath } from '../../utils/functions';
import { useWindowSize } from "../../utils/hooks";
import MGeneral from "../../assets/banners/bannerMGeneral.jpg"
import MRzr from "../../assets/banners/bannerMRZR.jpg"
import MSport from "../../assets/banners/bannerMSport.jpg"
import MRanger from "../../assets/banners/bannerMRanger.jpg"
import { Banners } from "./types";
import { Link } from "react-router-dom";

const desktopBanners = [
    {
        title: 'Desempenho para levar você a qualquer lugar',
        text: 'Testado nos ambientes mais dinâmicos, desafiadores e competitivos do mundo',
        image: rzr,
        top: '25%',
        right: '-27%'
    }, 
    {
        title: 'Defina seu próprio caminho com sportsman',
        text: 'Quadriciculos que oferecem liberdade e confiança. Para qualquer hora, seja trabalho ou passeio',
        image: sport,
        top: '25%',
        left: '3%',
        width: '44%'
    }, 
    {
        title: 'Trabalhos pesados com deslocamentos tranquilos',
        text: 'O UTV mais admirado e confiável dos fazendeiros por uma década',
        image: ranger,
        top: '25%',
        left: '2%',
        width: '51%'
    }, 
    {
        title: 'Quando o seu grupo deseja o melhor',
        text: 'Eleve sua aventura a outro patamar com a linha general',
        image: general,
        top: '25%',
        left: '4%'
    }
]

const mobileBanners = [{image: MRzr}, {image: MGeneral}, {image: MSport}, {image: MRanger}]

function getVehicles(group : string | undefined){
    return vehiclesList.find((vehicle) => vehicle.group === group)?.models
}

const brands = vehiclesList.map((brand) => brand.group)

export default function Home(){
    const [group, setGroup] = useState<string>('RZR')
    const [width] = useWindowSize();
    const [banners, setBanners] = useState<Banners[]>(desktopBanners)

    useEffect(() => {
        if(width <= 740) {
            setBanners(mobileBanners)
        } else {
            setBanners(desktopBanners)
        }
    }, [width])

    function switchCarGroup(group : string){
        setGroup(group)
    }

    return (
        <Template>
            <HomeContainer>
                <Swiper
                    modules={[Navigation, Autoplay, EffectFade]}
                    slidesPerView={1}
                    navigation
                    loop={true}
                    speed={1500}
                    autoplay={{delay: 2500, disableOnInteraction: false}}
                >
                {banners.map(({image, title, text, top, left, right, width}) => (
                    <SwiperSlide key={image}>
                        {title ? 
                            <Banner>
                                <Image src={image} alt='' />
                                <BannerInfo top={top} left={left} right={right}>
                                    <BannerTitle width={width}>{title}</BannerTitle>
                                    <BannerText width={width}>{text}</BannerText>
                                </BannerInfo>
                            </Banner>
                            :
                            <BannerMobile>
                                <MobileImage src={image} alt='' />
                            </BannerMobile>
                        }
                        
                    </SwiperSlide>
                ))}       
                </Swiper>
                <VehiclesCarousel>
                    <CarouselTitle>Veículos Polaris</CarouselTitle>
                    <GroupsList>
                        {brands.map((brand) => (<GroupItem active={group === brand} onClick={() => switchCarGroup(brand)}>{brand}</GroupItem>))}
                    </GroupsList>
                    <Wrapper>
                        <Swiper
                            modules={[Navigation, Autoplay]}
                            slidesPerView={4}
                            loop={true}
                            spaceBetween={30}
                            centerInsufficientSlides={true}
                            navigation
                            speed={1000}
                            autoplay={{delay: 5000, disableOnInteraction: false}}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                    
                                },
                                480: {
                                    slidesPerView: 2
                                },
                                640: {
                                    slidesPerView: 3
                                },
                                1024: {
                                    slidesPerView: 4
                                }
                            }}                          
                        >
                        {getVehicles(group)?.map((model) => (
                            <SwiperSlide key={model.name}>
                                <Link to={vehicleJsonToPath(group, model.name)}>
                                    <Image src={model.image} alt='' />
                                    <CarName>{model.name}</CarName>
                                    <Price>{currencyFormat.format(model.price + 3000)}</Price>
                                </Link>
                            </SwiperSlide>
                        ))} 
                        </Swiper>
                    </Wrapper>
                </VehiclesCarousel>
            </HomeContainer>
        </Template>
    )
}