import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterSt = styled.footer`
    background-color: hsl(0, 0%, 0%);
`

export const Flex = styled.div`
    display: flex;
`

export const StoreInfos = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2.5rem;

    @media (max-width: 846px) { 
        grid-column: 1 / span 2;
    }
`

export const Info = styled.span`
    padding-top: 2rem;
    font-weight: 600;
    font-size: 3rem;
    color: var(--secondary);
    white-space: nowrap;
`

export const Links = styled.div`
    padding-top: 4rem;
    padding-left: 5%;

    @media (max-width: 846px) { 
        grid-column: 1 / 2;
    }
`

export const TextContainer = styled.div`
    display: flex;

    @media (max-width: 846px) { 
        display: grid;
        grid-template-columns: auto 1fr;
    }
`

export const Logo = styled.img`
    -webkit-filter: invert();
    filter: invert();
    width: 30rem;
`

export const Media = styled.div`
    display: flex;
    padding-top: 2rem;
    font-size: 3rem;
    color: var(--secondary);
`

export const MediaLink = styled.a`
    padding: 0 1.2rem;
    color: var(--secondary);  
`

export const MediaIcon = styled.i`
    &:hover {
        color: var(--primary);
    }
`

export const Contact = styled.div`
    padding-left: 5%;
    padding-top: 4rem;

    @media (max-width: 846px) { 
        grid-column: 2 / 2;
    }
`

export const ContactTitle = styled.h1`
    padding-bottom: 1rem;
    font-weight: 600;
    font-size: 2rem;
    color: var(--primary);
`

export const ContactGrid = styled.div`
    display: grid;
    grid-template-columns: 30px 1fr;
    row-gap: 1rem;
`

export const ContactIcon = styled.i`
    font-size: 1.8rem;
    color: var(--secondary);
`

export const ContactItem = styled.span`
    transform: translateY(1px);
    font-size: 1.6rem;
    color: var(--secondary);
`

export const LinksList = styled.ul`
    display: inline-flex;
    flex-direction: column;
`

export const LinkItem = styled.li`
    padding-bottom: 1rem;
    font-size: 1.8rem;
    cursor: pointer;
    color: var(--secondary);
`

export const LinkTitle = styled.h2`
    font-weight: 600;
    cursor: text;
    font-size: 2rem;
    color: var(--primary);
`

export const LinkSt = styled(Link)`
    color: var(--secondary);
    &:hover {
        color: var(--primary);
    }
`

export const Adverstise = styled.p`
    padding: 2rem 0;
    text-align: center;
    font-size: 1rem;
    color: var(--secondary);
`



