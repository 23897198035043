import { useParams } from "react-router-dom";
import Template from "../../shared/Template";
import vehiclesList from "../../utils/vehiclesList";
import { Button, ButtonLink, Buttons, Car, CarImage, CarName, CarOverview, Description, Feature, Features, Logo, SpecName, Specification, Table, TableLine, TableRow } from "./styles";
import { Wrapper80 } from "../../globalStyles";
import { FaEnvelope, FaWhatsapp } from "react-icons/fa";
import { getWhatsUrl } from "../../utils/functions";
import ContactModal from "../../shared/ContactModal";
import { useState } from 'react';

function stringToJsonPattern(group : string | undefined, vehicle : string | undefined){

    const jsonGroup = group === 'rzr' ? group?.toUpperCase() : group ? group.charAt(0).toUpperCase() + group.slice(1) : group

    const jsonVehicle = vehicle?.toUpperCase().replace(/-/g,' ')

    return {jsonGroup, jsonVehicle}
}

export default function Vehicle(){
    const [openModal, setOpenModal] = useState(false)

    const whatsUrl = getWhatsUrl()

    const {group: groupParam, vehicle : vehicleParam} = useParams()

    const {jsonGroup, jsonVehicle} = stringToJsonPattern(groupParam, vehicleParam)

    const groupInfo = vehiclesList.find((group) => group.group === jsonGroup)
    const vehicleInfo = groupInfo?.models.find((model) => model.name === jsonVehicle)

    function toogleModal(){
        setOpenModal(!openModal)
    }
    
    return(
        <Template>
            <ContactModal openModal={openModal} toogleModal={toogleModal} carName={vehicleInfo?.name!}></ContactModal>
            <Wrapper80>
                <Car>
                    <div>
                        <Logo src={groupInfo?.logo} alt='logo' />
                        <CarImage src={vehicleInfo?.image} alt='car-image' />
                    </div>
                    <CarOverview>
                        <CarName>{vehicleInfo?.name}</CarName>
                        <Description>{vehicleInfo?.characteristics?.description}</Description>
                        <Features>
                            {vehicleInfo?.characteristics?.features.map((feature) => (<Feature key={feature}>{feature}</Feature>))}
                        </Features>
                        <Buttons>
                            <ButtonLink href={whatsUrl}><FaWhatsapp size={20}/>Fale com um consultor</ButtonLink>
                            <Button onClick={() => toogleModal()}><FaEnvelope size={20}/>Tenho interesse</Button>
                        </Buttons>
                    </CarOverview>          
                </Car>
                <Specification>Especificações</Specification>
                {vehicleInfo?.characteristics?.specifications?.map((spec) =>
                    <div key={spec.name}>
                        <SpecName>{spec.name}</SpecName>
                        <Table>
                            <tbody>
                            {spec?.items.map((item) => (
                                <TableRow key={item[0]}>
                                    <TableLine>{item[0]}</TableLine>
                                    <TableLine>{item[1]}</TableLine>
                                </TableRow>
                            ))}
                            </tbody>
                        </Table>
                    </div> 
                )}
            </Wrapper80>
        </Template>
    )
}