import { Link } from 'react-router-dom';
import Template from '../../shared/Template/Template';
import vehiclesList from '../../utils/vehiclesList';
import { Car, CarImage, CarName, Group, GroupTitle, HorLine, Logo, Price } from './styles';
import { Wrapper80 } from '../../globalStyles';
import { currencyFormat, vehicleJsonToPath } from '../../utils/functions';

export default function Vehicles(){
    return (
        <Template>
            <Wrapper80>
                {vehiclesList.map(vehicle => (
                <div key={vehicle.group}>
                    <GroupTitle>{vehicle.group}</GroupTitle>
                    <HorLine/>
                    <Group>
                    {vehicle.models.map(model => (           
                        <Link key={model.name} to={vehicleJsonToPath(vehicle.group, model.name)}>
                            <Car>
                                <Logo src={vehicle.logo} alt={vehicle.group} />
                                <CarImage src={model.image} alt="rzr2" />
                                <CarName>{model.name}</CarName>
                                <Price>{currencyFormat.format(model.price + 3000)}</Price>
                            </Car>
                        </Link>                  
                    ))}
                    </Group>                   
                </div>
                ))}            
            </Wrapper80>
        </Template>
    )
}