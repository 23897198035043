import Footer from "../Footer";
import Header from "../Header";
import MobileMenu from "../MobileMenu";
import { Main, TemplateSt, MobileWhatsIcon} from "./styles";
import { useState, useEffect } from 'react';
import whatsIcon from '../../assets/whatsapp.png'
import { getWhatsUrl } from "../../utils/functions";

type Props = {
    children: string | JSX.Element | JSX.Element[]
}

export default function Template({children} : Props){

    const whatsUrl = getWhatsUrl()

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

    function switchMenuStatus(){
        setIsMobileMenuOpen((prev) => !prev)
    }

    useEffect(() => {
        if(isMobileMenuOpen){
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto"
        }
    }, [isMobileMenuOpen])

    return (
    <TemplateSt>
        <MobileMenu switchMenuStatus={switchMenuStatus} isMobileMenuOpen={isMobileMenuOpen} />
        <Header switchMenuStatus={switchMenuStatus} isMobileMenuOpen={isMobileMenuOpen}/>
        <Main>
            {children}
        </Main>
        <Footer />
        <MobileWhatsIcon href={whatsUrl}>
            <img src={whatsIcon} alt="whats-icon" />
        </MobileWhatsIcon>
    </TemplateSt>)
}