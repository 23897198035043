import Requests from "../../pages/Vehicle/requests";
import { Background, Content, Footer, Header, Modal, ButtonClose, SendButton, Error  } from "./styles";
import { Inputs, Props } from "./types";
import { useForm } from "react-hook-form";

export default function ContactModal({openModal, toogleModal, carName} : Props){
    const { register, handleSubmit, watch, formState: { errors } } = useForm<Inputs>()

    function onSubmit(inputs : Inputs) {
        const requests = new Requests()
        requests.sendEmail(inputs.name, inputs.email, inputs.telephone, carName)

        toogleModal()
    }

    return (
            <Background openModal={openModal}>
                <Modal onSubmit={handleSubmit(onSubmit)} openModal={openModal}>
                    <Header>
                        <span>Tenho interesse</span>
                        <ButtonClose onClick={() => toogleModal()} type="button">x</ButtonClose>
                    </Header>
                    <Content>
                        <label htmlFor="">Nome</label>
                        <input type="text" {...register('name', { required: true })} />
                        {errors.name && <Error>Preencha o campo</Error>}
                        <label htmlFor="">Telefone</label>
                        <input type="text" {...register('telephone', { required: true })} />
                        {errors.telephone && <Error>Preencha o campo</Error>}
                        <label htmlFor="">Email</label>
                        <input type="text" {...register('email', { required: true })} />
                        {errors.email && <Error>Preencha o campo</Error>}
                    </Content>
                    <Footer>
                        <SendButton type="submit">Enviar</SendButton>
                    </Footer>
                </Modal>          
            </Background>
    )
}