import { Link } from "react-router-dom"
import styled from "styled-components"

export const CenterPage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`

export const Error = styled.h1`
    font-family: titillium web, sans-serif;
    font-size: 186px;
    font-weight: 900;
    margin: 0;
    text-transform: uppercase;
    -webkit-text-fill-color: transparent;
`

export const Title = styled.h2`
    font-family: titillium web,sans-serif;
    font-size: 26px;
    font-weight: 700;
`

export const Message = styled.p`
    font-family: montserrat,sans-serif;
    max-width: 767px;
    font-size: 14px;
    font-weight: 500;
    margin: 1.2rem 0 0rem;
    text-transform: uppercase;
`

export const Button = styled(Link)`
    font-family: titillium web,sans-serif;
    display: inline-block;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    border: none;
    background: var(--primary);
    padding: 10px 40px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 1px;
    margin-top: 15px;
    -webkit-transition: .2s all;
    transition: .2s all;
`