import styled from "styled-components";

export const Car = styled.div`
    display: flex;
    padding: 3rem;
`

export const CarOverview = styled.div`
    width: 40%;
    padding-top: 5%;
    padding-left: 2.4rem;
`

export const Logo = styled.img`
    display: flex;
    width: 40%;
`

export const CarImage = styled.img`
    width: 82%;
`

export const Features = styled.ul`
    list-style-type: square;
    margin-left: 2rem;
`

export const Feature = styled.li`
    font-size: 1.7rem;
    font-weight: 700;
    line-height: 2.1rem;
`

export const CarName = styled.h3`
    font-size: 2.5rem;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 1.1rem;
`

export const Description = styled.p`
    font-size: 1.6rem;
    padding-bottom: 1.1rem;
`

export const Specification= styled.h3`
    font-size: 1.9rem;
    text-align: center;
    background-color: hsl(210, 95%, 38%);
    padding: 1rem 0;
    margin-bottom: 1rem;
    border-radius: 9px;
    color: #ffff;
`

export const SpecName= styled.h2`
    padding-top: 2rem;
    font-size: 2.4rem;
    font-weight: 700;
`

export const Table = styled.table`
    border-collapse: collapse;
    margin: 2rem 0;
`

export const TableRow = styled.tr`
    &:nth-child(2n)  {
        background-color: hsl(0, 0%, 95%);
    }
`

export const TableLine = styled.td`
    font-size: 1.5rem;
    line-height: 1.9rem;
    padding: 0.5rem;
    border-top: 2px solid hsl(0, 0%, 90%);
    border-bottom: 2px solid hsl(0, 0%, 90%);
    &:first-child {
        width: 50rem;
        white-space: nowrap;
    }
`

export const Buttons = styled.div`
    display: flex;
    margin-top: 1.1rem;
`
export const ButtonLink = styled.a`
    margin-right: 1rem;
    display: inline-flex;
    align-items: center;
    border-radius: 9px;
    padding: 1rem 2rem;
    
    background-color: #383838;
    color: #ffff;
    font-size: 1.5rem;
    cursor: pointer;

    svg {
        margin-right: 3px;
    }
`

export const Button = styled.div`
    display: inline-flex;
    align-items: center;
    border-radius: 9px;
    padding: 1rem 2rem;
    background-color: hsl(210, 95%, 38%);
    color: #ffff;
    font-size: 1.5rem;
    cursor: pointer;

    svg {
        margin-right: 4px;
    }
`





