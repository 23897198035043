import styled from "styled-components";

export const GroupTitle = styled.h2`
    margin-top: 3rem;
    font-size: 3rem;
    font-weight: 700;
`

export const HorLine = styled.hr`
    background-color: var(--secondary);
    height: 1px;
    border: none;
    margin-bottom: 3rem;
`

export const Group = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, 32rem);
    justify-content: center;
    gap: 3rem;
    &:last-child {
        margin-bottom: 2.5rem;
    }
`

export const Car = styled.div`
    display: flex;
    flex-direction: column;
    border: 2px solid var(--secondary);
    padding: 1rem;
    cursor: pointer;
    &:hover {
        scale: 1.1;
    }
`

export const Logo = styled.img`
    height: 40px;
    margin: 0 auto;
`

export const CarImage = styled.img`
    height: 200px;
`

export const CarName = styled.span`
    padding-top: 1rem;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5rem;
    color: var(--black);
`

export const Price = styled.span`
    padding-top: 1rem;
    text-align: center;
    font-size: 1.5rem;
    color: var(--primary);
`

