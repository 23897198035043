import { MobileStatus } from '../../utils/globalTypes';
import styled from "styled-components";

export const MobileMenuSt = styled.div<MobileStatus>`
    display: ${props => props.isMenuOpen ? 'block' : 'none'};
    width: 100vw;
    height: 100vh;
    position: fixed;
    text-align: center;
    padding-top: 2rem;
    z-index: 300;
    background-color: #000000;

    > svg {
        display: block;
        margin-left: 4%;
        cursor: pointer;
        z-index: 4000;
        color: #FFF;
    }
`

export const NavMenu = styled.nav`
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    align-items: center;
    list-style-type: none;

    ul li {
        padding-bottom: 4rem;
        font-size: 3rem;
    }

    ul a {
        color: #ffff;
    }
`

export const Store = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 6rem;
`

export const Media = styled.div`
    display: flex;
    padding-top: 2rem;
    font-size: 3rem;
`

export const Icon = styled.i`
    cursor: pointer;
    font-size: 4rem;
    color: #ffff;
    margin-top: 1rem;
    padding: 0 2rem;
`

export const Telephone = styled.span`
    padding-top: 2rem;
    font-weight: 600;
    font-size: 3rem;
    color: #FFF;
`

export const Logo = styled.img`
    width: 30rem;
`