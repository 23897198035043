export const currencyFormat = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
})

export function vehicleJsonToPath(group : string, model : string) {
    return '/veiculo/' + group.toLowerCase() + '/' + model.toLowerCase().replace(/ /g, '-')
}

export function getWhatsUrl(){
    return 'https://wa.me/5541999589509'
}

