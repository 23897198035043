import logo from '../../assets/logos/CaisAzul.png'
import { Link } from "react-router-dom";
import { HeaderSt, LogoContainer, Logo, Navigation, PageList, 
    Item, LinkSt, Contact, WhatsNumber, Questions, Whatsicon } from './styles'
import { BiMenu } from "react-icons/bi";
import { HeaderProps } from './types';
import { getWhatsUrl } from '../../utils/functions';

const navItems = [
    {
        name: "Home",
        link: "/"
    },
    {
        name: "Veículos",
        link: "/veiculos"
    },
    {
        name: "Acessórios",
        link: "/acessorios"
    },
    {
        name: "Contato",
        link: "/contato"
    },
]

export default function Header({switchMenuStatus} : HeaderProps){
    const whatsUrl = getWhatsUrl()
    const telephone = whatsUrl.split('/')[3].slice(2)

    return (
        <HeaderSt>
            <BiMenu onClick={() => switchMenuStatus()} size={40} />
            <LogoContainer>
                <Link to='/'><Logo src={logo} alt="logo" /></Link>
            </LogoContainer>          
            <Navigation>
                <PageList>
                {navItems.map(item => <Item key={item.name}><LinkSt to={item.link}>{item.name}</LinkSt></Item>)}               
                </PageList>
            </Navigation>
            <Contact href={whatsUrl}>
                <Whatsicon size={25}></Whatsicon>
                <div>
                    <Questions>Perguntas? Whatsapp:</Questions>
                    <br/>
                    <WhatsNumber>{telephone.slice(0,-9) + ' ' + telephone.slice(2)}</WhatsNumber>
                </div>
            </Contact>
        </HeaderSt>
    )
}