import Vehicles from './pages/Vehicles';
import Acessories from './pages/Acessories';
import Vehicle from './pages/Vehicle';
import NotFound from './pages/NotFound';
import Home from './pages/Home/Home';

export const router = [
    {
        path: '/',
        element: <Home />,
        errorElement: <NotFound />,
    },
    {
        path: '/veiculos',
        element: <Vehicles/>,
        errorElement: <NotFound />,
    },
    {
        path: '/acessorios',
        element: <Acessories/>,
        errorElement: <NotFound />,
    },
    {
        path: '/veiculo/:group/:vehicle',
        element: <Vehicle/>,
        errorElement: <NotFound />,
    },
    {
        path: '/contato',
        element: <Home />,
        errorElement: <NotFound />,
    }
]