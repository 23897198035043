const palette = {
    primary: '#0457a9',
    primary2: '#273c54',
    secondary: '#a8a8a8',
    secondary2: '#74787b',
    secondary3: '#616161',
    black: '#181818',
    black2: '#404040'
};

const createCssVar = (items : any, prefix = '-'): string[] =>
    Object.entries(items).flatMap(([key, value]) => {
        const varName = `${prefix}-${key}`;
        if (typeof value === 'object')
            return createCssVar(value, varName);
    return `${varName}:${value}`;
});

export const cssColors = () =>
    createCssVar(palette).join(';');