import Template from "../../shared/Template";
import { Button, CenterPage, Error, Message, Title } from "./styles";
import texture from '../../assets/texture.webp'

export default function NotFound() {
    return (
        <Template>
            <CenterPage>
                <div>
                    <Error style={{background: `url(${texture})`, 
                        backgroundClip: 'text', 
                        backgroundSize: 'cover', 
                        backgroundPosition: 'center'}}>404</Error>
                    <Title>Oops! Página não encontrada</Title>
                    <Message>Desculpe, mas a página que você está procurando não existe, foi removida, teve o nome alterado ou está temporariamente indisponível.</Message>
                    <Button to='/'>Ir para Home</Button>
                </div>
            </CenterPage>
        </Template>
    )
}