import styled from "styled-components";
import { OpenModal } from "./types";

export const Background = styled.div<OpenModal>`
    display: ${props => props.openModal ? 'block' : 'none'};
    position: fixed;
    z-index: 6000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
`

export const Modal = styled.form<OpenModal>`
    display: ${props => props.openModal ? 'block' : 'none'};
    width: 400px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    border: blue;
    border-radius: 5px;
`

export const Header = styled.div`
    display: flex;
    padding: 12px 15px;
    justify-content: space-between;
    align-items: center;
    vertical-align: center;
    border-bottom: #eee solid 1px;

    span {
        font-size: 2.1rem;
        color: var(black2)
    }
`

export const Content = styled.div`
    display: flex;
    padding: 5px 15px;
    flex-direction: column;
    label {
        margin: 1.2rem 0 0.5rem;
        font-size: 1.4rem;
        font-weight: 500;
    }

    input {
        padding: 2px 0;
    }

    input:last-child {
        margin-bottom: 1rem;
    }
`

export const Footer = styled.div`
    display: flex;
    padding: 8px 15px;
    justify-content: flex-end;
    border-top: #eee solid 1px;
`

export const ButtonClose = styled.button`
    font-size: 2rem;
    background: none;
	color: var(--secondary);
	border: none;
	padding: 0;
	cursor: pointer;
	outline: inherit;
`

export const SendButton = styled.button`
    display: inline-flex;
    align-items: center;
    border-radius: 9px;
    border: none;
    padding: 1rem 2rem;
    background-color: hsl(210, 95%, 38%);
    color: #ffff;
    font-size: 1.5rem;
    cursor: pointer;
`

export const Error = styled.span`
    font-size: 1.1rem;
    margin-top: 2px;
    color: red;
`