import styled from 'styled-components'
import { NavLink } from "react-router-dom";
import { FaWhatsapp } from 'react-icons/fa';

export const HeaderSt = styled.header`
    display: flex;
    padding: 1rem 0;
    width: 100%;
    z-index: 200;
    box-shadow: 0 2px 3px 0 hsla(0, 0%, 0%, 0.15);
    background-color: hsl(0, 0%, 100%);
    position: relative;
    min-height: 40px;

    > svg {
        cursor: pointer;
        z-index: 4000;
        position: absolute;
        left: 4%;
        color: var(--black);
        @media (min-width: 768px) { 
            display: none;
        }
    }
`

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 5%;

    @media (max-width: 730px) {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 5px);
    }
`

export const Logo = styled.img`
    height: 3rem;
`

export const Navigation = styled.nav`
    display: flex;
    width: 100%;
    align-items: center;
    @media (max-width: 730px) { 
        display: none;
    }
`

export const PageList = styled.ul`
    width: 100%;
    display: flex;
    justify-content: center;
`

export const Item = styled.li`
    padding-right: 3.5vw;
    cursor: pointer;
    font-weight: 700;
    font-size: 2.1rem;
    color: var(--primary);
`

export const LinkSt = styled(NavLink)`
    color: var(--primary);
    &.active {
        color: var(--black);
    }
`

export const Contact = styled.a`
    display: flex;
    white-space: nowrap;
    justify-content: flex-end;
    margin-right: 2%;
    align-items: center;
    right: 10px;
    font-size: 1.5rem;
    color: var(--primary);
    line-height: 17px;

    @media (max-width: 965px) { 
        display: none;
    }
`

export const Questions = styled.span`
    color: var(--black);
    font-weight: 400;
`

export const Whatsicon = styled(FaWhatsapp)`
    margin-right: 4px;
`

export const WhatsNumber = styled.span`
    color: var(--primary);
    font-weight: 800;
`