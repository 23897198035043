import styled, { createGlobalStyle } from 'styled-components';
import { cssColors } from './palette';

export const Wrapper80 = styled.div`
    margin: 0 auto;
    width: 80%;
`

export const GlobalStyle = createGlobalStyle`
    :root {
        font-size: 10px;
        ${cssColors()}
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
        font-family: "Roboto", sans-serif;
    }

    body, html, #root, #root > div {
        height: 100%;
        width: 100%;
    }

    #root > div {
        display: flex;
        flex-direction: column;
    }

    a {
        text-decoration: none;
    }
`;

export const ResetStyle = createGlobalStyle`
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure, 
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    body {
        line-height: 1;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
`;