import styled from "styled-components";
import { BannerPosition, BannerWidth, Groupitem } from "./types";

export const Image = styled.img`
    width: 100%;
`

export const CarouselTitle = styled.h2`
    font-weight: 600;
    font-size: 3.6rem;
    color: var(--secondary2);
`
export const HomeContainer = styled.div`
    .disabled_swiper_button {
        opacity: 0;
        cursor: auto;
        pointer-events: none;
    }

    .swiper-button-prev,
    .swiper-button-next, 
    .swiper-pagination-bullets {
        display: none;
    }
`

export const VehiclesCarousel = styled.div`
    margin-top: 2rem;
    text-align: center;
`

export const GroupsList = styled.ul`
    display: inline-flex;
    padding-top: 2rem;
`

export const GroupItem = styled.li<Groupitem>`
    padding-right: 3.2rem;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 2rem;
    font-weight: 600;
    color: ${props => props.active ? 'var(--black)' : 'var(--primary)'};

    &:hover{
        transform: scale(1.1);
    }

    @media (max-width: 480px) {
        padding-right: 2rem;

        &:hover{
            transform: none;
        }
    }

    @media (max-width: 405px) {
        padding-right: 1rem;
    }
`

export const Wrapper = styled.div`
    margin: 2rem auto;
    width: 90%;
`

export const CarName = styled.span`
    color: var(--black);
    text-transform: uppercase;
    font-size: 1.5rem;
`

export const Price = styled.span`
    display: block;
    margin-top: 0.4rem;
    color: var(--primary);
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5rem;
`

export const Banner = styled.div`
    position: relative;
`

export const BannerInfo = styled.div<BannerPosition>`
    position: absolute;
    top: ${props => props.top};
    left: ${props => props.left};
    right: ${props => props.right};
`

export const BannerTitle = styled.h2<BannerWidth>`
    color: #fff;
    width: ${props => props.width ? props.width : '60%'};
    font-weight: 600;
    font-size: 2.8vw;
    margin-bottom: 1rem;
`

export const BannerText = styled.p<BannerWidth>`
    font-family: titillium web, sans-serif;
    width: ${props => props.width ? props.width : '60%'};
    font-weight: 500;
    color: #fff;
    font-size: 1.8vw;
`

export const BannerMobile = styled.div`
    overflow: hidden;
    width: 100%;
    padding-bottom: 110%;
    position: relative;
`

export const MobileImage = styled.img`
    width: 100%;
    position: absolute;
`


