import { Icon, Logo, Media, MobileMenuSt, NavMenu, Store, Telephone } from "./styles";
import { MobileMenuProps } from "./types";
import { BiMenu } from "react-icons/bi";
import polarisLogo from '../../assets/logos/PolarisLogo.png'
import { Link } from "react-router-dom";

export default function MobileMenu({isMobileMenuOpen, switchMenuStatus} : MobileMenuProps){

    return (
        <MobileMenuSt isMenuOpen={isMobileMenuOpen}>
            <BiMenu onClick={() => switchMenuStatus()} size={40} />
            <NavMenu>
                <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/veiculos">Veículos</Link></li>
                <li><a href="/acessorios">Acessórios</a></li>
                <li><a href="/contato">Contato</a></li>
                </ul>
            </NavMenu>

            <Store>
                <Logo src={polarisLogo} alt="polaris-logo" />
                <Telephone>(41) 3203-0076</Telephone>
                <Media>
                    <Icon className="fab fa-instagram"></Icon>
                    <Icon className="fab fa-facebook-square"></Icon>
                    <Icon className="fab fa-youtube"></Icon>
                </Media>
            </Store>
        </MobileMenuSt>
    )
}