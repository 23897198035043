import axios from "axios";

const url = window.location.protocol + '//' + window.location.hostname

export default class Requests {
    sendEmail(name : string, email : string, telephone: string, carName: string){
        return axios.get(url + '/email', {params: {name: name, email: email, car: carName, telephone: telephone}})
    }
}


