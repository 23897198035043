import { StoreInfos, Links, Contact, TextContainer, FooterSt, Logo, Info, ContactItem, 
    ContactGrid, ContactIcon, ContactTitle, Media, MediaLink, MediaIcon, LinksList, LinkItem, Adverstise, LinkSt, LinkTitle } from './styles';
import logo from '../../assets/logos/logo-polaris-black.svg'
import { Wrapper80 } from '../../globalStyles';

const instagramLink = 'https://www.instagram.com/polariscuritiba'

export default function Footer(){
    return (
        <FooterSt>
            <Wrapper80>
                <TextContainer>
                    <StoreInfos>
                        <Logo src={logo} alt="logo" />
                        <Info>(41) 3203-0076</Info>
                        <Info>CNPJ: 44.107.182/0001-70</Info>
                        <Media>
                            <MediaLink href={instagramLink}><MediaIcon className="fab fa-instagram"></MediaIcon></MediaLink>
                            <MediaLink href={instagramLink}><MediaIcon className="fab fa-facebook-square"></MediaIcon></MediaLink>
                            <MediaLink href={instagramLink}><MediaIcon className="fab fa-youtube"></MediaIcon></MediaLink>
                        </Media>
                    </StoreInfos>
                    <Links>
                        <nav>
                            <LinksList>
                                <LinkItem><LinkTitle>Links</LinkTitle></LinkItem>
                                <LinkItem><LinkSt to="/">Home</LinkSt></LinkItem>
                                <LinkItem><LinkSt to="/veiculos">Veículos</LinkSt></LinkItem>
                                <LinkItem><LinkSt to="/acessorios">Acessórios</LinkSt></LinkItem>
                                <LinkItem><LinkSt to="/contato">Contato</LinkSt></LinkItem>
                            </LinksList>
                        </nav>
                    </Links>
                    <Contact>
                        <ContactTitle>Contatos</ContactTitle>
                        <ContactGrid>
                            <ContactIcon className="fas fa-map-marker-alt"></ContactIcon>
                            <ContactItem>Alameda Júlia da Costa, 2807 - São Francisco, Curitiba - PR, 80730-095</ContactItem>
                            <ContactIcon className="fas fa-phone-alt"></ContactIcon>
                            <ContactItem>(41) 3203-0076</ContactItem>
                            <ContactIcon className="far fa-envelope"></ContactIcon>
                            <ContactItem>vendas@caispolaris.com.br</ContactItem>
                        </ContactGrid>
                    </Contact>
                </TextContainer>
                <div>
                    <Adverstise>
                        Todas as imagens são meramente ilustrativas. Modelos e cores e especificações dos veículos podem
                        diferir das imagens apresentadas. Alguns equipamentos apresentados podem ser opcionais e/ ou
                        acessórios. Para mais informações sobre disponibilidade de modelos, cores e especificações,
                        consulte um de nossos vendedores ou venhas até nossa loja. As informações do site estão sujeitas a
                        modificações sem prévio aviso.
                    </Adverstise>
                </div>
            </Wrapper80>
        </FooterSt>
    )
}