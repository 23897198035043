import Template from '../../shared/Template';
import pdf from '../../assets/documents/acessorios-2022.pdf'
import { Wrapper } from './styles';


export default function Acessories(){
    return (
        <Template>
            <Wrapper>
                <object data={pdf} type="application/pdf" width="100%" height="700px">
                </object>
            </Wrapper>
        </Template>
    )
}